<template>
  <div>
    <div>
      <h1 class="card-title pt-5">
        You do not have access to any pages
      </h1>
    </div>
  </div>
</template>

<script>

export default {
  computed: {

  }
}
</script>

<style scoped>

</style>
